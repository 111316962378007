import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import ImageUploading from 'react-images-uploading';
import BeatLoader from 'react-spinners/BeatLoader';

export default function ResponsiveDialog(props) {
    // eslint-disable-next-line
    const [componentType, setComponentType] = useState("create");
    const [callingBackend, setCallingBackend] = useState(false);
    const { open, handleClose, loadData } = props;
    let [image, setImage] = useState([]);
    const [finsihed, setFinished] = useState(false);
    const [finalValues, setFinalValues] = useState(null);
    const [check, setCheck] = useState(true);

    const [initialValues, setInitialValues] = useState({
        firstName: props.initialValues?.firstName || "",
        lastName: props.initialValues?.lastName || "",
        email: props.initialValues?.email || "",
        userType: props.initialValues?.userType || "",
        images: [props.initialValues?.profilePic] || []
    });
    let validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("firstName is required!")
            .max(35, "Max length for the name is 35")
            .min(2, "Min length for the name is 2"),
        lastName: Yup.string()
            .required("lastName is required!")
            .max(35, "Max length for the name is 35")
            .min(2, "Min length for the name is 2"),
        email: Yup.string()
            .email('Invalid email format')
            .required("userName is required!"),
    });

    useEffect(() => {
        if (props.initialValues) {
            setComponentType("update")
            setValues()
        }
        // eslint-disable-next-line
    }, [props.initialValues]);

    const setValues = () => {
        setInitialValues({
            firstName: props.initialValues?.firstName || "",
            lastName: props.initialValues?.lastName || "",
            email: props.initialValues?.email || "",
            userType: props.initialValues?.userType || "",
            images: [props.initialValues?.profilePic] || []
        });
        setImage(props.initialValues?.profilePic === "" ? [] : [props.initialValues?.profilePic]);
    }

    const handleInput = (e) => {
        setInitialValues((pre) => ({
            ...pre,
            [e.target.name]: e.target.value
        }));
    };

    const publishProduct = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")
                    }`,
            },
        };
        setCheck(false)
        let payload = {}
        if (finalValues?.images[0]?.url || finalValues?.images[0] === "") {
            payload = {
                userType: finalValues?.userType,
                email: finalValues?.email,
                firstName: finalValues?.firstName,
                lastName: finalValues?.lastName,

            }
        } else {
            payload = {
                userType: finalValues?.userType,
                email: finalValues?.email,
                firstName: finalValues?.firstName,
                lastName: finalValues?.lastName,
                profilePic: finalValues?.images

            }
        }

        axios.put(`/user/${props.initialValues._id}`, payload, config).then((res) => {
            setInitialValues({ "userName": "", "email": "", "userType": "", "userStatus": "", "images": "" })
            setFinalValues(null)
            setCallingBackend(false);
            handleClose();
            loadData();
            props.setSuccesMessage(true);
            if (res.status === 200) {
                setCheck(true)
            }
        }).catch((error) => {
            props.setFailMessage(true);
            setCheck(true)
        })
    }

    const submit = async () => {

        if (image[0]?.data_url) {
            setFinalValues({ ...initialValues, images: image[0]?.data_url });
        }
        else if (image?.length == 0) {
            setFinalValues({ ...initialValues, images: "" })
        } else {
            setFinalValues({ ...initialValues })
        }
    };
    useEffect(() => {
        if (finalValues) {
            setCallingBackend(false);
            setFinished(true);
        }
    }, [finalValues]);


    const maxNumber = 1;
    const onChange = (imageList) => {
        setImage(imageList);
        setCallingBackend(true)
    };
    useEffect(() => {
        setCallingBackend(false)
    }, [image])


    useEffect(() => {
        if (finsihed)
            publishProduct()
        setFinished(false)

    }, [finsihed]);

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ isValid, getFieldProps }) => {
                    return (
                        <Form>
                            <Dialog
                                fullWidth="100%"
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    Edit a User
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2} >
                                        <Grid item md={6}>
                                            <Grid>
                                                <Field
                                                    {...getFieldProps('firstName')}
                                                    onChange={handleInput}
                                                    name="firstName"
                                                    label="First Name"
                                                    component={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline

                                                ></Field>
                                            </Grid>
                                            <Grid style={{ marginTop: "1rem" }}>
                                                <Field
                                                    {...getFieldProps('lastName')}
                                                    onChange={handleInput}
                                                    name="lastName"
                                                    label="Last Name"
                                                    component={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                ></Field>
                                            </Grid>
                                            <Grid style={{ marginTop: "1rem" }}>
                                                <Field
                                                    onChange={handleInput}
                                                    name="email"
                                                    label="Email"
                                                    component={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                ></Field>
                                            </Grid>
                                            <Grid style={{ marginTop: "1rem" }}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="demo-simple-select-label">
                                                        User Type
                                                    </InputLabel>
                                                    <Select
                                                        onChange={handleInput}
                                                        value={initialValues.userType ? initialValues.userType : ""}
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        label="User Type"
                                                        name="userType"
                                                    >
                                                        <MenuItem value={"user"}>User</MenuItem>
                                                        <MenuItem value={"admin"}>Admin</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6}>
                                            <ImageUploading
                                                multiple
                                                value={image}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                                name="images"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                    errors
                                                }) => (
                                                    <div className="upload__image-wrapper">
                                                        <button
                                                            style={isDragging ? { color: 'red' } : undefined}
                                                            onClick={onImageUpload}
                                                            {...dragProps}
                                                        >
                                                            Click or Drop here
                                                        </button>
                                                        &nbsp;
                                                        {imageList?.length > 0
                                                            ? (imageList?.map((image, index) => (
                                                                < div key={index} className="image-item" >
                                                                    <img src={image?.data_url ? image?.data_url : image?.url} alt="" width="100" />
                                                                    <div className="image-item__btn-wrapper">            <button onClick={() => {
                                                                        onImageRemove(index)
                                                                    }}>Remove</button>
                                                                    </div>
                                                                </div>
                                                            ))) : <p>No Image</p>}
                                                        <div>
                                                            {errors?.maxNumber && <span>Number of selected images exceed max number of images</span>}
                                                        </div>
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                {callingBackend ? <BeatLoader /> :
                                    <DialogActions>
                                        <Button
                                            onClick={submit}
                                            variant="contained"
                                            type="submit"
                                            disabled={!check || !isValid}
                                            color="primary"
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            color="secondary"
                                            onClick={handleClose}
                                            autoFocus
                                        >
                                            cancel
                                        </Button>
                                    </DialogActions>
                                }

                            </Dialog>
                        </Form>
                    );
                }}
            </Formik>

        </div >
    );
}
