import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import BeatLoader from 'react-spinners/BeatLoader';
import DropFileInput from '../components/drop-file-input/DropFileInput'
import '../../styles/styles.css'
import DeleteIcon from '@material-ui/icons/Delete';

export default function ResponsiveDialog(props) {
  const [componentType, setComponentType] = useState("create");
  const [callingBackend, setCallingBackend] = useState(false);
  const { open, handleClose, loadData } = props;
  const [finsihed, setFinished] = useState(false);
  const [finalValues, setFinalValues] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [check, setCheck] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: props.initialValues?.name || "",
    funding: props.initialValues?.funding || "",
    unassignedUnits: props.initialValues?.unassignedUnits || "",
    industry: props.initialValues?.industry || "",

  });
  let validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required!")
      .max(35, "Max length for the name is 35")
      .min(2, "Min length for the name is 2"),
    funding: Yup.string()
      .required("Funding is required!"),
    unassignedUnits: Yup.number()
      .required("Unassigned Units is required!")
  });

  useEffect(() => {
    if (props.initialValues) {
      setComponentType("update")
      setValues()
    }
  }, [props.initialValues]);

  const setValues = () => {
    setInitialValues({
      name: props.initialValues?.name || "",
      funding: props.initialValues?.funding || "",
      unassignedUnits: props.initialValues?.unassignedUnits || "",
      industry: props.initialValues?.industry || "",
      iPSensitiveDetails: props.initialValues?.iPSensitiveDetails || "",

    });
  }

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const handleInput = (e) => {
    setInitialValues((pre) => ({
      ...pre,
      [e.target.name]: e.target.value
    }));
  };

  const publishProduct = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")
          }`,
      },
    };
    setCheck(true)
    let payload = {}
    if (documents) {
      payload = {
        name: finalValues?.name,
        funding: finalValues?.funding,
        unassignedUnits: finalValues?.unassignedUnits,
        industry: finalValues?.industry,
        iPSensitiveDetails: finalValues?.iPSensitiveDetails,
      }
    } else {
      payload = {
        name: finalValues?.name,
        funding: finalValues?.funding,
        unassignedUnits: finalValues?.unassignedUnits,
        industry: finalValues?.industry,

      }
    }

    axios.put(`/project/admin/${props.initialValues._id}`, payload, config).then((res) => {
      setInitialValues({ "name": "", "funding": "", "unassignedUnits": "", "industry": "", "iPSensitiveDetails": [] })
      setFinalValues(null)
      setCallingBackend(false);
      setDocuments(null)
      handleClose();
      loadData();
      props.setSuccesMessage(true);
      if (res.status === 200) {
        setCheck(false)
      }
    })
      .catch((error) => {
        props.setFailMessage(true);
        setCheck(false)
      })

  }

  const submit = async () => {

    if (documents) {
      setFinalValues({ ...initialValues, iPSensitiveDetails: [...props.initialValues?.iPSensitiveDetails, ...documents] })
    } else {
      setFinalValues({ ...initialValues })
    }
  };


  let newDocuments = [];
  const deleteDocuments = (indexDoc) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")
          }`,
      },
    };


    newDocuments = props.initialValues?.iPSensitiveDetails.filter((item, index) => index !== indexDoc)
    setFinalValues({ ...initialValues, iPSensitiveDetails: newDocuments })
    if (finalValues) {
      axios.put(`/project/admin/${props.initialValues._id}`, finalValues, config).then((res) => {
        setInitialValues({ "name": "", "funding": "", "unassignedUnits": "", "industry": "", "iPSensitiveDetails": [] })
        setFinalValues(null)
        setCallingBackend(false);
        setDocuments(null)
        handleClose();
        loadData();
        props.setSuccesMessage(true);
      })
        .catch((error) => {
          props.setFailMessage(true);
        })
    }


  }



  useEffect(() => {
    if (finalValues) {
      setCallingBackend(false);
      setFinished(true);
    }
  }, [finalValues]);



  useEffect(() => {
    if (finsihed)
      publishProduct()
    setFinished(false)

  }, [finsihed]);

  const onFileChange = (files) => {
    setDocuments(files)
  };



  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ errors, isValid, dirty, handleChange, values, setFieldValue, getFieldProps }) => {
          return (
            <Form>
              <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  Edit a Project
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} >
                    <Grid item md={6}>
                      <Grid>
                        <Field
                          {...getFieldProps('name')}
                          onChange={handleInput}
                          name="name"
                          label="Name"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          multiline
                        ></Field>
                      </Grid>
                      <Grid style={{ marginTop: "1rem" }}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label">
                            Industry
                          </InputLabel>
                          <Select
                            onChange={handleInput}
                            value={initialValues.industry ? initialValues.industry : ""}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Industry"
                            name="industry"
                          >
                            <MenuItem value="Consumer Discretionary">Consumer Discretionary</MenuItem>
                            <MenuItem value="Consumer Staples">Consumer Staples</MenuItem>
                            <MenuItem value="Construction">Construction</MenuItem>
                            <MenuItem value="Energy">Energy</MenuItem>
                            <MenuItem value="Materials">Materials</MenuItem>
                            <MenuItem value="Industrials">Industrials</MenuItem>
                            <MenuItem value="Healthcare">Healthcare</MenuItem>
                            <MenuItem value="Financials">Financials</MenuItem>
                            <MenuItem value="Information Technology">Information Technology</MenuItem>
                            <MenuItem value="Real Estate">Real Estate</MenuItem>
                            <MenuItem value="Communication Services">Communication Services</MenuItem>
                            <MenuItem value="Utilities">Utilities</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid style={{ marginTop: "1rem" }}>
                        <Field
                          onChange={handleInput}
                          name="funding"
                          label="Funding"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                        ></Field>
                      </Grid>
                      <Grid style={{ marginTop: "1rem" }}>
                        <Field
                          onChange={handleInput}
                          name="unassignedUnits"
                          label="Unassinged Units"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                        ></Field>
                      </Grid>
                      <Grid style={{ marginTop: "2rem" }}>
                        <h5>Uploaded Documents</h5>
                        {props.initialValues?.iPSensitiveDetails?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{ display: "flex" }}
                            >
                              <div style={{
                                overflow: "hidden", textOverflow: "ellipsis", flex: 1, margin: "auto"
                              }}>
                                <a href={item.url} style={{ fontSize: "0.875rem", }}>
                                  {item.url}
                                </a>
                              </div>
                              <div >
                                <Button
                                  disabled={props.initialValues?.iPSensitiveDetails?.length === 1}
                                  onClick={() => deleteDocuments(index)}
                                  color="secondary"
                                  variant="outlined"
                                >
                                  <DeleteIcon

                                  />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </Grid>
                    </Grid>

                    <Grid item md={6}>
                      <Grid
                        item
                        sx={{
                          border: "1px dashed ",
                          borderRadius: "8px",
                        }}
                      >
                        <div className="box">
                          <h2 className="header">Documents Uplaoder</h2>
                          <DropFileInput
                            onFileChange={(files) => onFileChange(files)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                {callingBackend ? <BeatLoader /> :
                  <DialogActions>
                    <Button
                      onClick={submit}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!isValid || check}
                    >
                      Update
                    </Button>
                    <Button
                      onClick={handleClose}
                      autoFocus
                      color="secondary"
                    >
                      cancel
                    </Button>
                  </DialogActions>
                }
              </Dialog>
            </Form>
          );
        }}
      </Formik>

    </div>
  );
}
